import React from 'react';
import { graphql, PageProps } from 'gatsby';
import MainLayout from '@/layouts/MainLayout';
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next';
import { OutlineButton } from '@/components/buttons';
import { InnovationCard } from '@/components/cards';
import { InterestedToLearnMore } from '@/components/sections';
import { useMd, useLg } from '@/hooks/responsive';
import WelcomeSVG from '@/icons/welcome.inline.svg';
import WelcomeBGSmall from '@/icons/welcome-bg-small-screen.inline.svg';
import WelcomeBGLarge from '@/icons/welcome-bg-large-screen.inline.svg';
import Seo from '@/components/Seo';
import BlueBoxSVG from '@/icons/blue-box.inline.svg';

type IndexPageProps = PageProps<GatsbyTypes.IndexPageQuery>;

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const isMd = useMd();
  const sLg = useLg();

  const innovations = (
    language === 'th' ? data.innovationsTH : data.innovationsEN
  )?.pages?.home?.section_3?.components?.innovations;

  return (
    <MainLayout>
      <Seo title={t('seo.home.title')} description={t('seo.home.desc')} />

      {/* Section 1: TONG HUA DATA LAB INC. */}
      <section className="bg-primary-main relative overflow-hidden flex justify-center items-center">
        <WelcomeBGSmall className="absolute lg:hidden" />
        <WelcomeBGLarge className="hidden lg:block absolute 2xl:w-full 2xl:h-full" />
        <div className="full-page relative flex flex-col justify-center lg:flex-row-reverse lg:justify-between items-center px-4 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
          <div className="h-1/2 lg:h-full w-full flex justify-center lg:w-1/2 xl:pl-16">
            <WelcomeSVG className="w-4/5 sm:w-2/3 lg:w-full h-full" />
          </div>
          <div className="h-1/2 lg:h-full flex flex-col lg:justify-center text-center lg:text-left lg:w-1/2 w-full xl:pr-16">
            <h1 className="text-4xl lg:text-6xl font-semibold text-secondary-main whitespace-pre-line !leading-snug">
              {sLg
                ? t('pages.home.section-1.header-1')
                : isMd
                ? t('pages.home.section-1.md-header-1')
                : t('pages.home.section-1.header-1')}
            </h1>
            <h2 className="text-2xl lg:text-3xl text-neutral-50 mt-3">
              {t('pages.home.section-1.desc-1')}
            </h2>
            <Link to="/about" className="mt-10 inline-block">
              <OutlineButton
                title={t('components.buttons.about-us')}
                className="hover:bg-secondary-main hover:text-neutral-900"
              />
            </Link>
          </div>
        </div>
      </section>

      {/* Section 2: Section Problem Statement */}
      <section className="bg-secondary-border">
        <div className="px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
          <h2 className="text-4xl font-semibold text-center text-neutral-900 whitespace-pre-line">
            {t('pages.home.section-2.header-1')}
          </h2>
        </div>
      </section>

      {/* Section 3: We Innovate Tech */}
      <section className="px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
        <h2 className="text-4xl font-semibold text-center text-neutral-900">
          {t('pages.home.section-3.header-1')}
        </h2>
        <h3 className="text-xl text-center text-neutral-900 mt-4">
          {t('pages.home.section-3.desc-1')}
        </h3>

        <div className="mt-16 flex flex-col space-y-10 lg:flex-row lg:space-y-0">
          {innovations?.map((innovation, key) => {
            const { title, desc, icon } = innovation || {};
            return (
              <div key={key} className="w-full lg:w-1/3 lg:px-2.5">
                <InnovationCard
                  icon={icon?.childImageSharp?.gatsbyImageData}
                  title={title}
                  desc={desc}
                />
              </div>
            );
          })}
        </div>
      </section>

      {/* Section 4: Solution */}
      <section className="bg-primary-main">
        <div className="px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto overflow-hidden">
          <div className="flex flex-col items-center justify-center space-y-5">
            <h2 className="text-4xl font-semibold text-center text-neutral-50">
              {t('pages.home.section-4.header-1')}
            </h2>
            <div className="w-16 bg-secondary-main h-0.5" />
          </div>
          <div className="mt-16 flex flex-col lg:flex-row-reverse lg:items-center lg:justify-between">
            <div className="relative flex justify-center lg:mx-0">
              <BlueBoxSVG className="relative z-10 w-full" />
            </div>
            <div className="flex flex-col items-center justify-center text-center lg:text-left lg:items-start space-y-7 lg:w-3/5 mt-24 lg:mt-0">
              <h2 className="text-2xl font-semibold text-secondary-main">
                {t('pages.home.section-4.header-2')}
              </h2>
              <h3 className="text-xl text-neutral-50">
                {t('pages.home.section-4.desc-2')}
              </h3>
              <Link to="/solution">
                <OutlineButton
                  title={t('components.buttons.learn-more')}
                  className="hover:bg-secondary-main hover:text-neutral-900"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Section 5: Interested to learn more */}
      <InterestedToLearnMore />
    </MainLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($language: String!) {
    innovationsEN: enJson {
      pages {
        home {
          section_3 {
            components {
              innovations {
                title
                desc
                icon {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    innovationsTH: thJson {
      pages {
        home {
          section_3 {
            components {
              innovations {
                title
                desc
                icon {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "translation" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
